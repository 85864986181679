import React, { Component } from 'react';
import './style.css';

export class Divider extends Component {
  render() {
    return (
      <div className="divider"></div>
    )
  }
}

export default Divider;
