import { Types } from '../constants/actionTypes';

  let addProfileAction = (user) => ({ type: Types.ADD_USER, payload: { user } })

  let updateProfileImageAction = (image) => ({ type: Types.UPDATE_PROFILE_PICTURE, payload: { image } })

  let updateProfileAction = (user) => ({ type: Types.UPDATE_USER, payload: { user } })

  let loginAction = (user) => ({ type: Types.LOGIN, payload: { user } })

  let addDayInfoToProfileAction = (dayInfo) => ({ type: Types.ADD_DAY_INFO, payload: { dayInfo } })

export const ActionCreators = {

  addProfile: (user) => {
      return (dispatch, getState) => {
          let requestParams = {
              method: 'POST',
              mode: 'cors',
              body: JSON.stringify(user),
              headers: {
                  'Content-Type': 'application/json'
              },
          }
          const request = new Request("https://perf1-prox.ccs.uky.edu/api/registration", requestParams)

          fetch(request)
            .then((response) => response.json())
            .then((json) => {
                let userWithId = {...user, id: json["id"]}
                dispatch(addProfileAction(userWithId))
            })
      }
  },

  updateProfileImage: (image) => {
      return (dispatch, getState) => {
          dispatch(updateProfileImageAction(image))
      }
  },

  updateProfile: (user) => {
      return (dispatch, getState) => {
          dispatch(updateProfileAction(user))
      }
  },

  formSubmittionStatus: (status) => ({ type: Types.FORM_SUBMITION_STATUS, payload: { status }}),

  login: (user) => {
      return (dispatch, getState) => {
          dispatch(loginAction(user))
      }
  },

  addDayInfoToProfile: (dayInfo) => {
      return (dispatch, getState) => {
          dispatch(addDayInfoToProfileAction(dayInfo))
      }
  }
}
