import React, { Component } from 'react';
import './style.css';

export class Footer extends Component {
  render() {
    return (
      <footer>Copyright © 2020 JebaSuthan, Inc. All Rights Reserved</footer>
    )
  }
}

export default Footer;
