import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import { connect } from 'react-redux';
import { ActionCreators } from '../../../../actions/profile';
import { MultiSelect } from '../../../../components';
import stateList from '../../../../mock/state.json';
import { formatPhoneNumber, isValidEmail } from '../../../../utils';
import './style.css';

export class RightContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        institution: '',
        department: '',
        interests: [],
        dietaryRestrictions: '',
        needParking: null,
        profileImage: '',
        dayInfo: [],
      },
      errors: {
        user: {
          firstName: 'Enter First Name',
          lastName: 'Enter Last Name',
          phone: 'Enter Phone',
          email: 'Email is not valid!'
        }
      },
      validForm: false,
      submitted: false
    }
  }

  componentDidMount() {
    if(this.props.profile) {
      this.setState({ user: this.props.profile });
      if (this.props.profile.email) {
        this.resetErrorMsg();
      }
    }
  }

  validationErrorMessage = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'firstName':
        errors.user.firstName = value.length < 1 ? 'Enter First Name' : '';
        break;
      case 'lastName':
        errors.user.lastName = value.length < 1 ? 'Enter Last Name' : '';
        break;
      case 'email':
        errors.user.email = isValidEmail(value) ? '' : 'Email is not valid!';
        break;
      case 'phone':
        errors.user.phone = value.length < 1 && value.length > 10 ? 'Enter valid telephone number' : '';
        break;
      default:
        break;
    }

    this.setState({ errors });
  }

  inputChange = (event) => {
    let phone = ''
    const { name, value } = event.target;
    const user = this.state.user;
    if (name === 'phone') {
      phone = formatPhoneNumber(value);
      user[name] = phone;
    } else {
      user[name] = value;
    }
    this.setState({ user });
    this.validationErrorMessage(event);
  }

  checkboxChange = (event) => {
    const { name, checked } = event.target;
    const user = this.state.user;
    user[name] = checked;
    this.setState({ user });
  }

  onSelectedInterest = (value) => {
    const user = this.state.user;
    const errors = this.state.errors;
    user['interests'] = value;
    errors.user.interests = value.length < 1 ? 'Enter your Interests' : '';
    this.setState({ user, errors });
  }

  validateForm = (errors) => {
    let valid = true;
    Object.entries(errors.user).forEach(item => {
      console.log(item)
      item && item[1].length > 0 && (valid = false)
    })
    return valid;
  }

  submitForm = async (event) => {
    this.setState({ submitted: true });
    this.props.dispatch(ActionCreators.formSubmittionStatus(true));
    const user = this.state.user;
    if (user && this.props.profile) {
      user.profileImage = this.props.profile.profileImage;
    }
    event.preventDefault();
    if (this.validateForm(this.state.errors) && this.props.profile && this.props.profile.profileImage) {
      console.info('Valid Form')
      this.props.dispatch(ActionCreators.addProfile(user));
      this.props.history.push('/confirm')
    } else {
      console.log('Invalid Form')
    }
  }

  resetErrorMsg = () => {
    let errors = this.state.errors;
    errors.user.firstName = ''
    errors.user.lastName = ''
    errors.user.phone = ''
    errors.user.email = ''
    errors.user.interests = ''
    this.setState({ errors });
  }

  render() {
    const { firstName, lastName, email, phone, institution, department, interests, dietaryRestrictions, needParking } = this.state.user;
    const { submitted } = this.state;
    const listState = stateList.listStates.map((item, key) =>
      <option key={key} value={item.name}>{item.name}</option>
    );
    return (
      <div className="rightPanel">

        <div className="row">
          <label className="col-sm-2 col-form-label">Name</label>
          <div className="col-sm-3 mb-2">
            <input type="text" value={this.state.user.firstName} name="firstName" onChange={(e) => { this.inputChange(e)} } className="form-control" placeholder="First Name" />
            { submitted && this.state.errors.user.firstName.length > 0 &&  <span className='error'>{this.state.errors.user.firstName}</span>}
          </div>
          <div className="col-sm-3 mb-2">
            <input type="text" value={this.state.user.lastName} name="lastName" onChange={(e) => { this.inputChange(e)} } className="form-control" placeholder="Last Name" />
          </div>
          <div className="col-sm-4">
          </div>
        </div>

        <div className="row">
          <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
          <div className="col-sm-6 mb-2">
            <input type="email" value={this.state.user.email} name="email" onChange={(e) => { this.inputChange(e)} } className="form-control" id="email" placeholder="itjebasuthan@gmail.com" />
            { submitted && this.state.errors.user.email.length > 0 &&  <span className='error'>{this.state.errors.user.email}</span>}
          </div>
          <div className="col-sm-4">
          </div>
        </div>

        <div className="row">
          <label htmlFor="phone" className="col-sm-2 col-form-label">Phone</label>
          <div className="col-sm-6 mb-2">
            <input type="text" pattern="[0-9]" maxLength="14" value={this.state.user.phone} name="phone" onChange={(e) => { this.inputChange(e)} }  className="form-control" id="phone" placeholder="(212)477-1000" />
            { submitted && this.state.errors.user.phone.length > 0 &&  <span className='error'>{this.state.errors.user.phone}</span>}
          </div>
          <div className="col-sm-4">
          </div>
        </div>

        <div className="row">
          <label htmlFor="institution" className="col-sm-2 col-form-label">Institution</label>
          <div className="col-sm-6 mb-2">
            <input type="text" value={this.state.user.institution} name="institution" onChange={(e) => { this.inputChange(e)} }  className="form-control" id="institution" placeholder="Institution or University" />
          </div>
          <div className="col-sm-4">
          </div>
        </div>

        <div className="row">
          <label htmlFor="department" className="col-sm-2 col-form-label">Department</label>
          <div className="col-sm-6 mb-2">
            <input type="text" value={this.state.user.department} name="department" onChange={(e) => { this.inputChange(e)} }  className="form-control" id="department" placeholder="Department" />
          </div>
          <div className="col-sm-4">
          </div>
        </div>

        <div className="row">
          <label htmlFor="staticEmail1" className="col-sm-2 col-form-label">Interests</label>
          <div className="col-sm-6 mb-2">
            <MultiSelect className="form-control" searchPlaceholder="Interests" selected={this.state.user.interests} onSelect={this.onSelectedInterest} />
            { submitted && this.state.errors.user.interests.length > 0 &&  <span className='error'>{this.state.errors.user.interests}</span>}
          </div>
          <div className="col-sm-4">
          </div>
        </div>

        <div className="row">
          <label htmlFor="dietaryRestrictions" className="col-sm-2 col-form-label">Diet</label>
          <div className="col-sm-6 mb-2">
            <input type="text" value={this.state.user.dietaryRestrictions} name="dietaryRestrictions" onChange={(e) => { this.inputChange(e)} }  className="form-control" id="dietaryRestrictions" placeholder="Leave blank if none" />
          </div>
          <div className="col-sm-4">
          </div>
        </div>

        { this.state.user.institution.toLowerCase() !== 'university of kentucky' &&
        <div className="row">
          <label htmlFor="needParking" className="col-sm-2 col-form-label">Need Parking?</label>
          <div className="col-sm-6 mb-2">
            <input type="checkbox" value={this.state.user.needParking} name="needParking" onChange={this.checkboxChange}  id="needParking" style={{margin: '10px'}} />
          </div>
          <div className="col-sm-4">
          </div>
        </div>
        }

        <div className="row">
          <div className="col-sm-5 mb-2">
          </div>
          <div className="col-sm-4">
            <button type="button" className="button" onClick={this.submitForm}>Submit</button>
          </div>
          <div className="col-sm-3"></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.user.profile
  }
}

export default connect(mapStateToProps)(withRouter(RightContent));
