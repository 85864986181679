import React, { Component } from 'react';
import './style.css';

export class Header extends Component {
  render() {
    return (
      <header>User - Registration</header>
    )
  }
}

export default Header;
